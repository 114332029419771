import React, { useEffect, useRef, useState, memo } from 'react';
import { Container, Typography, Box, Grid, Card, CardContent, useTheme, useMediaQuery, Paper, Skeleton } from '@mui/material';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import {
  Assignment as AssignmentIcon,
  DesignServices as GraphicDesignIcon,
  Search as SearchIcon,
  Security as SecurityIcon,
  Dashboard as DashboardIcon,
  BarChart as BarChartIcon,
  Apps as AppsIcon,
  SettingsInputComponent as SettingsInputComponentIcon,
  Edit as EditIcon,
  Speed as SpeedIcon,
  Lock as LockIcon,
  Backup as BackupIcon,
 AddToPhotos as AddToPhotos,
} from '@mui/icons-material';
import { Fade } from "react-awesome-reveal";
import LazyLoad from 'react-lazyload';
import { debounce } from 'lodash';
import ComingSoonIntern from '../Componente/ComingSoonIntern';
import PricingWeb from '../PricingWeb';
import ProgressiveSlider from '../ProgressiveSlider';
import Slider, { Settings } from 'react-slick';
import { motion } from 'framer-motion';

gsap.registerPlugin(ScrollTrigger);
interface CardProps {
  card: {
    title: string;
    description: string;
    icon: React.ReactNode;
  };
  index: number;
}
// Memoized Card component to prevent unnecessary re-renders

const ServiciiWeb: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));


  const MemoizedCard = memo(({ card, index}: CardProps) => (
  
  <Card className="card-prezentare" sx={{boxShadow:'0px 1px 1px 0px rgba(0,0,0,0.25),0px 2px 8px 0px rgba(0,0,0,0.25),0px 0px 0px 1px rgba(255,255,255,0.1) inset', maxWidth: isMobile ? '95%' : '80%', maxHeight:'100%',  margin:'auto', marginTop: isMobile ? 3:30}}>
             
             <div className="search-overlay-card-prezentare"></div>
             <Skeleton animation={false} className="overlay-card-prezentare" variant="text" />
             <div className="bara-start-overlay-card-prezentare"></div>
             <div className="bulina-overlay-card-prezentare1"></div>
             <div className="bulina-overlay-card-prezentare2"></div>
             <div className="bulina-overlay-card-prezentare3"></div>
        
             <CardContent style={{ textAlign: 'center' }}>
               <div style={{ marginBottom: '1rem', alignSelf:'left' }}>{card.icon}</div>
               <Typography variant="h6" component="h2" gutterBottom>
                 {card.title}
               </Typography>
               <Typography variant="body2" color="textSecondary">
                 {card.description}
               </Typography>
             </CardContent>
           </Card>
  

  ), (prevProps, nextProps) => (
    prevProps.card === nextProps.card &&
    prevProps.index === nextProps.index
  
  ));

  
  // Refs to the Paper component and the image
  const paperRef = useRef<HTMLDivElement>(null);
  const avionRef = useRef<HTMLImageElement>(null);

  // State to track if the image has loaded
  const [imageLoaded, setImageLoaded] = useState(false);

  const images = [
    { src: 'apache-original.svg', name: 'Apache' },
    { src: 'bash-original.svg', name: 'Bash' },
    { src: 'bootstrap-original.svg', name: 'Bootstrap' },
    { src: 'css3-original.svg', name: 'CSS3' },
    { src: 'dotnetcore-original.svg', name: '.NET Core' },
    { src: 'github-original.svg', name: 'GitHub' },
    { src: 'html5-original.svg', name: 'HTML5' },
    { src: 'json-original.svg', name: 'JSON' },
    { src: 'materialui-original.svg', name: 'Material UI' },
    { src: 'mysql-original.svg', name: 'MySQL' },
    { src: 'nextjs-original.svg', name: 'Next.js' },
    { src: 'npm-original-wordmark.svg', name: 'NPM' },
    { src: 'nuget-original.svg', name: 'NuGet' },
    { src: 'php-original.svg', name: 'PHP' },
    { src: 'react-original.svg', name: 'React' },
    { src: 'sqldeveloper-original.svg', name: 'SQL Developer' },
    { src: 'swagger-original.svg', name: 'Swagger' },
    { src: 'tailwindcss-original.svg', name: 'Tailwind CSS' },
    { src: 'visualstudio-original.svg', name: 'Visual Studio' },
    { src: 'vuejs-original.svg', name: 'Vue.js' },
    { src: 'woocommerce-original.svg', name: 'WooCommerce' },
    { src: 'wordpress-original.svg', name: 'WordPress' },
    { src: 'powershell-original.svg', name: 'Powershell' },
  ];
  
  // Handler for image load event
  const handleImageLoad = () => {
    setImageLoaded(true);
  };
  const settings: Settings = {
    dots: false,
    infinite: true,
    speed: 500, // Increase speed for smoother transitions
    slidesToShow: isMobile ? 2 : 5,
    slidesToScroll: 1,
    autoplay: true,
    swipeToSlide: true,
    autoplaySpeed: 2000, // Controls the delay between slides
    lazyLoad: 'ondemand', // Lazy load images on demand
    pauseOnHover: false, // Pause autoplay on hover
  };
  const cardsData = [
    {
        title: 'Cod Personalizat',
        description: 'Dezvoltăm soluții web personalizate pentru a satisface nevoile specifice ale afacerii tale. Indiferent dacă este vorba de aplicații web complexe sau de funcționalități unice, echipa noastră de dezvoltatori va crea cod personalizat pentru a asigura performanță, scalabilitate și integrare perfectă cu alte sisteme.',
        icon: <SettingsInputComponentIcon fontSize="large" sx={{ color: 'dodgerblue' }} />,
    },
    {
        title: 'Single Page Applications (SPA)',
        description: 'Construim aplicații web single-page (SPA) care oferă o experiență de utilizare fluidă și interactivă. Cu SPA-urile noastre, vei beneficia de încărcări rapide ale paginilor și o navigare fără întreruperi, ideal pentru aplicații complexe sau pentru site-uri cu multe funcționalități.',
        icon: <SpeedIcon fontSize="large" sx={{ color: 'dodgerblue' }} />,
    },
    {
        title: 'Dezvoltare WordPress',
        description: 'Oferim soluții complete pentru dezvoltarea și personalizarea site-urilor WordPress. Fie că ai nevoie de un site nou, de personalizare a unui site existent, sau de dezvoltarea unor plugin-uri și teme personalizate, echipa noastră va asigura funcționalitatea optimă și designul atrăgător al site-ului tău.',
        icon: <DashboardIcon fontSize="large" sx={{ color: 'dodgerblue' }} />,
    },
    {
        title: 'Integrări API',
        description: 'Specializați în integrarea API-urilor pentru a extinde funcționalitățile aplicațiilor tale web. Vom conecta aplicația ta cu diverse servicii externe, inclusiv plăți, date externe, și funcționalități suplimentare, pentru a îmbunătăți experiența utilizatorului și eficiența operațiunilor.',
        icon: <AppsIcon fontSize="large" sx={{ color: 'dodgerblue' }} />,
    },
    {
        title: 'Optimizare Performanță Web',
        description: 'Ne concentrăm pe optimizarea performanței site-ului tău pentru a asigura timpi de încărcare rapizi și o experiență plăcută pentru utilizatori. Serviciile noastre includ analiza și îmbunătățirea performanței, optimizarea resurselor și implementarea celor mai bune practici pentru viteza web.',
        icon: <SpeedIcon fontSize="large" sx={{ color: 'dodgerblue' }} />,
    },
    {
        title: 'Securitate Web Avansată',
        description: 'Asigurăm soluții de securitate avansate pentru site-ul tău, protejându-l împotriva amenințărilor și atacurilor cibernetice. Implementăm măsuri de securitate, criptare și monitorizare pentru a proteja datele și a asigura integritatea site-ului tău.',
        icon: <LockIcon fontSize="large" sx={{ color: 'dodgerblue' }} />,
    },
    {
        title: 'Managementul Conținutului',
        description: 'Oferim soluții eficiente pentru gestionarea și actualizarea conținutului web. Cu ajutorul instrumentelor noastre, vei putea să adaugi, editezi și organizezi conținutul site-ului tău cu ușurință, asigurându-te că informațiile sunt întotdeauna actuale și relevante.',
        icon: <EditIcon fontSize="large" sx={{ color: 'dodgerblue' }} />,
    },
    {
        title: 'Analiza și Monitorizarea Traficului',
        description: 'Furnizăm soluții pentru analiza și monitorizarea traficului web. Aceste instrumente îți permit să urmărești și să analizezi comportamentul vizitatorilor pe site-ul tău, să identifici oportunitățile de îmbunătățire și să optimizezi strategiile de marketing.',
        icon: <BarChartIcon fontSize="large" sx={{ color: 'dodgerblue' }} />,
    },
    {
        title: 'Servicii de Back-up și Recuperare',
        description: 'Oferim soluții complete de back-up și recuperare a datelor pentru a asigura continuitatea afacerii tale în caz de incidente neprevăzute. Serviciile noastre includ back-up-uri automate, gestionarea copiilor de siguranță și strategii rapide de recuperare a datelor.',
        icon: <BackupIcon fontSize="large" sx={{ color: 'dodgerblue' }} />,
    },
    {
        title: 'Consultanță și Strategie Digitală',
        description: 'Furnizăm consultanță expertă în domeniul strategiei digitale. Echipa noastră va analiza nevoile tale și va dezvolta strategii personalizate pentru a maximiza eficiența și succesul afacerii tale online, inclusiv planificarea campaniilor de marketing și optimizarea proceselor.',
        icon: <AssignmentIcon fontSize="large" sx={{ color: 'dodgerblue' }} />,
    },
];


const routeVariants = {
  initial: {
    opacity: 0,
    y: -20,
    scale: 0.8,
  },
  final: {
    opacity: 1,
    y: 0,
    scale: 1,
    transition: {
      duration: 0.5,
      ease: 'easeInOut',
    },
  },
};
useEffect(() => {
  // Get the height of the viewport
  const viewportHeight = window.innerHeight;
  
  // Scroll smoothly by 100vh
  window.scrollTo({
    top: 0.8 * viewportHeight,
    behavior: 'smooth'
  });
}, []);

  useEffect(() => {
    const avion = avionRef.current;
    const paperElement = paperRef.current;
  
    if (avion && paperElement && imageLoaded) {
      const paperHeight = paperElement.offsetHeight;
      const avionHeight = avion.offsetHeight;
      const offset = avionHeight * 2;
  
      const endPosition = paperHeight - avionHeight - offset;
  
      gsap.fromTo(avion,
        { y: 0, opacity:0 },
        { opacity:1,
          y: endPosition, 
          ease: 'power1.out',
          scrollTrigger: {
            trigger: paperElement,
            start: () => `top ${window.innerHeight / 2 - avionHeight}px`,
            end: () => `+=${paperHeight - window.innerHeight + avionHeight + offset}`,
            scrub: 1,
            markers: false,
          }
        }
      );
    }
  }, [imageLoaded]);

  return (
    <motion.div
    variants={routeVariants}
    initial="initial"
    animate="final"
    className="acasa-component"
  >
    
    <Box id="serviciiWebCarduri" sx={{ position: 'relative', width: '100%', height: 'fit-content' }}>
      <div id="serviciiWebInceput"></div>
      <Container sx={{mt: isMobile ? 2 : 30, mb: isMobile ? 2 : 2}}>

<Typography variant="h2" component="h1" gutterBottom sx={{ fontWeight: 700, fontSize: { xs: '1.8rem', sm: '2.5rem', md: '3rem', lg: '4rem' } }}>
Împreună pentru Succes
        </Typography>
        <Typography variant="h5" component="h2" gutterBottom sx={{ fontSize: { xs: '1rem', sm: '1.2rem', md: '1.5rem', lg: '1.8rem' } }}>
        Ne plac provocările și suntem nerăbdători să vedem ce ne pregătești! Nu construim doar site-uri, ci creăm parteneriate durabile. 
        </Typography>
        
</Container>
<ProgressiveSlider  images={images} settings={settings}/>

      <Box className="Sectiune" sx={{ mb: 5 }}>
    
                <Grid container spacing={2} direction={isMobile ? 'column' : 'row'}>
                  <Grid item xs={12} sm={7} sx={{ mt: isMobile ? 2 : 4, mb: isMobile ? 5 : 4 }}>
                    <Box sx={{ mt: 10, margin:'auto', maxWidth: isMobile ? '99%' : '70%' }} className="TextConversieContainer">
                      <div className="gridDiv"></div>
                      <Fade>
                       <Typography variant="h2" component="h1" gutterBottom sx={{ fontWeight: 700, fontSize: { xs: '1.8rem', sm: '1.9rem', md: '2rem', lg: '3rem' } }}>
                          Soluții Web Inovatoare
                        </Typography>
                      </Fade>
                      <Container sx={{ display: 'flex', justifyContent: 'center', mr: isMobile ? 0 : 10 }}>
                        <Fade cascade triggerOnce>
                     <AddToPhotos fontSize="large" sx={{ fontSize:70, color: 'dodgerblue' }} />

                        </Fade>
                      </Container>
                      <Fade>
                        <Typography variant="h5" component="h2" gutterBottom sx={{ mt: 2, fontSize: { xs: '1rem', sm: '1.2rem', md: '1.3rem', lg: '1.4rem' } }}>
                          Transformăm ideile tale în soluții web avansate! Specializați în dezvoltarea de site-uri web personalizate, de la design modern la implementare eficientă, oferim soluții care răspund perfect nevoilor tale digitale. Fie că este vorba despre un site de prezentare sau o platformă complexă, suntem aici să aducem viziunea ta la viață.
                        </Typography>
                      </Fade>
                    </Box>
                  </Grid>
                  <Grid item xs={4} sm={5} id="containerMareLoginDraw" sx={{ overflow: 'visible', mt: isMobile ? 2 : 4, mb: isMobile ? 10 : 4 }}>
                    <Box sx={{ width: '100%', height: 'auto', display: 'flex', justifyContent: 'center' }}>
                      <img src="/imagini/iamgine-web-dev.png" alt="Dezvolatere site-uri web" style={{ maxWidth: '100%', height: 'auto' }} />
                    </Box>
                  </Grid>
                </Grid>
              </Box>


      <Grid container spacing={3} sx={{position:'relative'}}>
        { isMobile ? ( 
          <>
          </>
        ) : (
          <>
          <img src="/imagini/cloud1.png" alt="Servicii Web development" className="nor1"/>
          <img src="/imagini/cloud2.png" alt="Servicii Web development" className="nor2"/>
          <img src="/imagini/cloud4.png" alt="Servicii Web development" className="nor4"/>
          </>
        )
    
      }
        {/* Left Grid Item */}
        <Grid item xs={12} md={5}>
          <Paper
            sx={{
              border: 0,
              boxShadow: '0px 0px 0px black',
              background: 'transparent',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              height: '100%',
              overflowY: 'auto',
              p: 2,
            }}
          >
            {cardsData.filter((_, index) => index % 2 === 0).map((card, index) => (
                <Fade cascade damping={1.5} triggerOnce>
              <MemoizedCard
                card={card}
                index={index}
               
              />
              </Fade>
            ))}
            
          </Paper>
        </Grid>
        
        {/* Middle Grid Item */}
        <Grid item xs={12} md={2}>
          <Paper 
            sx={{ 
               // Conditional styling based on isMobile
              border: 0,
              boxShadow: '0px 0px 0px black',
              background: 'transparent',
              mb: 2,
              height: '100%',
              position: 'relative',
              overflow: 'hidden',
              display: isMobile ? 'none' : 'flex', 
              justifyContent: 'center',
              alignItems: 'center',
            }} 
            ref={paperRef}
          >
      
              <img
                className="avion"
                src="/imagini/avion.png"
                alt="Servicii Web"
                ref={avionRef}
                onLoad={handleImageLoad}
                style={{
                  position: 'absolute',
                  top: 0,
                }}
              />
           
        
          </Paper>
        </Grid>
        
        {/* Right Grid Item */}
        <Grid item xs={12} md={5}>
          <Paper
            sx={{
              border: 0,
              boxShadow: '0px 0px 0px black',
              background: 'transparent',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              height: '100%',
              overflowY: 'auto',
              p: 2,
            }}
          >
              <Box sx={{height:200}}>    </Box>
            {cardsData.filter((_, index) => index % 2 !== 0).map((card, index) => (
              <Fade cascade damping={1.5} triggerOnce>
              <MemoizedCard
                card={card}
                index={index}
                
              />
              </Fade>
            ))}
        
          </Paper>
        </Grid>
      </Grid>
      
  

  <PricingWeb />
    </Box>
    </motion.div>
  );
};

export default ServiciiWeb;
