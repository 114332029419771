import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Button,
  useMediaQuery,
  useTheme,
  Drawer,
  Menu,
  MenuItem,
  ThemeProvider,
  createTheme,
  Collapse,
  List,
  ListItem,
  ListItemText,
  Box,
  Divider,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';

const Navbar: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [scrolling, setScrolling] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [dropdownAnchor, setDropdownAnchor] = useState<null | HTMLElement>(null);
  const [serviciiAccordionOpen, setServiciiAccordionOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setScrolling(window.scrollY > 50);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleDropdownToggle = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (isMobile) {
      setMenuOpen(false); // Close mobile menu when opening dropdown
      setIsDropdownOpen((prev) => !prev);
      setDropdownAnchor(event.currentTarget);
    } else {
      setIsDropdownOpen((prev) => !prev);
      setDropdownAnchor(event.currentTarget);
    }
  };

  const handleDropdownClose = () => {
    setIsDropdownOpen(false);
    setDropdownAnchor(null);
  };

  const handleDrawerClose = () => {
    setMenuOpen(false);
  };

  const handleServiciiClick = () => {
    setServiciiAccordionOpen((prev) => !prev);
  };

  const handleMenuItemClick = () => {
    setIsDropdownOpen(false);
    setDropdownAnchor(null);
    isMobile ?  setMenuOpen(false) :  setMenuOpen(false);
  };

  const themeWithTransitions = createTheme({
    components: {
      MuiAppBar: {
        styleOverrides: {
          root: {
            transition: 'background-color 0.3s ease-out, height 0.3s ease-out',
          },
        },
      },
      MuiToolbar: {
        styleOverrides: {
          root: {
            transition: 'padding-top 0.3s ease-out, min-height 0.3s ease-out',
          },
        },
      },
      MuiDrawer: {
        styleOverrides: {
          paper: {
            transition: theme.transitions.create('width', {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.enteringScreen,
            }),
            width: 320,
          },
        },
      },
    },
  });

  return (
    <ThemeProvider theme={themeWithTransitions}>
      <AppBar
        position="fixed"
        sx={{
          top: 0,
          backgroundColor: scrolling ? 'rgba(255, 255, 255, 1)' : 'transparent',
          boxShadow: scrolling ? theme.shadows[1] : 'none',
          height: scrolling ? 56 : 72,
          transition: 'background-color 0.3s, height 0.3s, box-shadow 0.3s',
          zIndex: theme.zIndex.drawer + 1,
          opacity: menuOpen ? 0 : 1,
        }}
      >
        <Toolbar
          sx={{
            minHeight: scrolling ? 56 : 72,
            paddingLeft: isMobile ? 0 : theme.spacing(2),
            paddingRight: isMobile ? 0 : theme.spacing(2),
            '& button': {
              transition: 'all 0.2s ease',
              fontWeight: scrolling ? '600' : '600',
              fontSize: isMobile ? '1rem' : '1rem',
            },
            marginLeft: isMobile ? 3 : 10,
            marginRight: isMobile ? 3 : 20,
          }}
        >
          <Typography
            variant="h6"
            component="div"
            sx={{
              flexGrow: 1,
              color: scrolling ? 'black' : 'white',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <img
              src={scrolling ? '/logo-ivs-black.png' : '/logo-ivs.png'}
              alt="IT VISION SOFTWARE"
              style={{ maxHeight: 40, marginLeft: 10 }}
            />
          </Typography>
          {isMobile ? (
            <>
              <IconButton edge="end" aria-label="menu" onClick={() => setMenuOpen(!menuOpen)}>
                <MenuIcon sx={{ color: scrolling ? 'black' : 'white', marginRight: 2 }} />
              </IconButton>
              <Drawer
                anchor="right"
                open={menuOpen}
                onClose={handleDrawerClose}
                sx={{
                  '& .MuiDrawer-paper': {
                    width: 320,
                  },
                }}
              >
                <Box display="flex" justifyContent="flex-end" p={2}>
                  <IconButton onClick={handleDrawerClose}>
                    <CloseIcon />
                  </IconButton>
                </Box>
                <List>
                  <ListItem>
                    <img
                      src="/logo-ivs-black.png"
                      alt="IVSLP Logo"
                      style={{ maxHeight: 40, margin: '0 auto' }}
                    />
                  </ListItem>
                  <Divider />
                  <ListItem button component={Link} to="/acasa" onClick={handleDrawerClose}>
                    <ListItemText primary="ACASA" />
                  </ListItem>
                  <ListItem button component={Link} to="/contact" onClick={handleDrawerClose}>
                    <ListItemText primary="CONTACT" />
                  </ListItem>
                  <ListItem button onClick={handleServiciiClick}>
                    <ListItemText primary="SERVICII" />
                  </ListItem>
                  <Collapse in={serviciiAccordionOpen} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      <ListItem button component={Link} to="/servicii-web" onClick={handleMenuItemClick}>
                        <ListItemText primary="Servicii Web" />
                      </ListItem>
                      <ListItem button component={Link} to="/servicii-software" onClick={handleMenuItemClick}>
                        <ListItemText primary="Servicii Software" />
                      </ListItem>
                    </List>
                  </Collapse>
                  <ListItem button component="a" href="https://cloud.itvisionsoftware.ro" target="_blank">
      <ListItemText primary="CLOUD" />
    </ListItem>
                </List>
              </Drawer>
            </>
          ) : (
            <>
              <Button color="inherit" component={Link} to="/acasa" sx={{ color: scrolling ? 'black' : 'white' }}>ACASA</Button>
              <Button color="inherit" component={Link} to="/contact" sx={{ color: scrolling ? 'black' : 'white' }}>CONTACT</Button>
              <Button
                color="inherit"
                aria-controls="servicii-menu"
                aria-haspopup="true"
                onClick={handleDropdownToggle}
                sx={{ color: scrolling ? 'black' : 'white' }}
              >
                SERVICII
              </Button>
              <Menu
                id="servicii-menu"
                anchorEl={dropdownAnchor}
                open={isDropdownOpen}
                onClose={handleDropdownClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    borderRadius:'5px',
                    border:'2px solid rgba(0,0,0,0.2)',
                    mt: 2,
                    minWidth: 200,
                  },
                }}
              >
                <MenuItem component={Link} to="/servicii-web" onClick={handleMenuItemClick}>Servicii Web</MenuItem>
                <MenuItem component={Link} to="/servicii-software" onClick={handleMenuItemClick}>Servicii Software</MenuItem>
              </Menu>
              <Button color="inherit" target="_blank" href="https://cloud.itvisionsoftware.ro/" sx={{ color: scrolling ? 'black' : 'white' }}>CLOUD</Button>
            </>
          )}
        </Toolbar>
      </AppBar>
    </ThemeProvider>
  );
};

export default Navbar;
